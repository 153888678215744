@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Barlow', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}